const config = {
  menuLinks: [
    {
      name: "Projects",
      to: "/projects/",
    },
    {
      name: "Articles",
      to: "/articles/",
    },
  ],
}

module.exports = config
