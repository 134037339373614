import React, { useState } from "react"

const TwitterIcon = props => {
  return (
    <svg
      width="20px"
      height="16px"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Social"
          transform="translate(-20.000000, -142.000000)"
          fill="#000000"
        >
          <g id="icons" transform="translate(20.000000, 20.000000)">
            <path
              d="M6.29,138 C13.837,138 17.965,131.843653 17.965,126.505461 C17.965,126.330213 17.965,126.155949 17.953,125.982669 C18.756,125.411634 19.449,124.702763 20,123.8915 C19.252,124.218368 18.457,124.432999 17.644,124.527515 C18.5,124.022444 19.141,123.228903 19.448,122.292602 C18.642,122.763214 17.761,123.095005 16.842,123.273207 C15.288,121.646742 12.689,121.567979 11.036,123.097958 C9.971,124.08447 9.518,125.555377 9.849,126.958351 C6.55,126.794917 3.476,125.260999 1.392,122.737616 C0.303,124.583634 0.86,126.944568 2.663,128.129957 C2.01,128.111251 1.371,127.937971 0.8,127.624887 L0.8,127.676083 C0.801,129.598895 2.178,131.254896 4.092,131.635915 C3.488,131.798364 2.854,131.821993 2.24,131.704833 C2.777,133.350988 4.318,134.47829 6.073,134.51078 C4.62,135.635128 2.825,136.245545 0.977,136.243575 C0.651,136.242591 0.325,136.223885 0,136.185487 C1.877,137.370877 4.06,138 6.29,137.997046"
              id="twitter-[#154]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TwitterIcon
